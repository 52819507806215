function Work() {
  return (
    <div
      data-aos="fade-left"
      data-aos-duration={500}
      data-aos-delay={200}
      data-aos-once="true"
      className="rn-testimonial-area testimonial-style-2 section-height"
    >
      <div className="inner">
        <h5 className="title">Work Experience</h5>
        <div className="rn-testimonial-carousel testimonial-item-one rn-slick-dot-style dot-position-left slick-arrow-style-one arrow-top-align">
          <div className="testimonial-inner" style={{ paddingBottom: "35px" }}>
            <div className="testimonial-header">
              <div>
                <img
                  src="assets/images/icons/coinbase.png"
                  alt="coinbase"
                  style={{ height: "4rem", width: "4rem" }}
                />
              </div>
              <h5 className="ts-header" style={{ fontSize: "2rem" }}>
                Software Developer
                <span
                  className="text-color-primary"
                  style={{ fontSize: "2rem" }}
                >
                  <br />
                  <a href="https://www.coinbase.com/about">@Coinbase</a>
                </span>
              </h5>
            </div>
            <div className="testimonial-body">
              <p className="discription">
                • Working at Coinbase Wallet team to ship user-facing features
                to 5M monthly active crypto wallet users
              </p>
              <p className="discription">
                • Maintained native iOS codebase, and currently maintaining
                React web app & cross-platform React Native apps on iOS &
                Android
              </p>
            </div>
          </div>
          <div className="testimonial-inner" style={{ paddingBottom: "10px" }}>
            <div className="testimonial-header">
              <div>
                <img
                  src="assets/images/icons/ansys.png"
                  alt="ansys"
                  style={{ height: "3.75rem", width: "4rem" }}
                />
              </div>
              <h5 className="ts-header" style={{ fontSize: "2rem" }}>
                Electronics Software Dev. and Testing Intern
                <span
                  className="text-color-primary"
                  style={{ fontSize: "2rem" }}
                >
                  <br />
                  <a href="https://www.ansys.com/company-information">@Ansys</a>
                </span>
              </h5>
            </div>
            <div className="testimonial-body">
              <p className="discription">
                • Building tools that automate software development and testing
                infrastructure processes
              </p>
              <p className="discription">
                • Conducting interactive and automated testing of user
                interfaces and simulation results
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Work;
