import Education from "./Education";
import MySkills from "./MySkills";
import Work from "./Work";

function RightCloumn() {
  return (
    <div className="col-lg-6">
      <div className="sticky-home-wrapper" style={{ marginBottom: "100px" }}>
        <MySkills />
        <Work />
        <Education />
      </div>
    </div>
  );
}

export default RightCloumn;
