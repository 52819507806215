function Education() {
  return (
    <div
      data-aos="fade-left"
      data-aos-duration={500}
      data-aos-delay={200}
      data-aos-once="true"
      className="rn-testimonial-area testimonial-style-2 section-height"
    >
      <div className="inner">
        <h5 className="title">Education</h5>
        <div className="rn-testimonial-carousel testimonial-item-one rn-slick-dot-style dot-position-left slick-arrow-style-one arrow-top-align">
          <div className="testimonial-inner" style={{ paddingBottom: "35px" }}>
            <div className="testimonial-header">
              <div>
                <img
                  src="assets/images/icons/neu.png"
                  alt="Northeastern University"
                  style={{ height: "3.2rem", width: "4rem" }}
                />
              </div>
              <h5 className="ts-header" style={{ fontSize: "2rem" }}>
                M.Sc. in Information Systems
                <span
                  className="text-color-primary"
                  style={{ fontSize: "2rem" }}
                >
                  <br />
                  <a href="https://www.northeastern.edu/experience">
                    @Northeastern University
                  </a>
                </span>
              </h5>
            </div>
            <div className="testimonial-body">
              <p className="discription">
                • Lead at Google Developer Student Club at Northeastern
                University, San Francisco Bay Area.
              </p>
              <p className="discription">
                • Relevant Classes: Application Engineering and Development,
                Program Structure and Algorithms, Smartphone-Based Web
                Development
              </p>
            </div>
          </div>
          <div className="testimonial-inner" style={{ paddingBottom: "10px" }}>
            <div className="testimonial-header">
              <div>
                <img
                  src="assets/images/icons/scut.png"
                  alt="ansys"
                  style={{ height: "4rem", width: "4rem" }}
                />
              </div>
              <h5 className="ts-header" style={{ fontSize: "2rem" }}>
                B.Eng. in Information Engineering
                <span
                  className="text-color-primary"
                  style={{ fontSize: "2rem" }}
                >
                  <br />
                  <a href="https://en.wikipedia.org/wiki/South_China_University_of_Technology">
                    @South China University of Technology
                  </a>
                </span>
              </h5>
            </div>
            <div className="testimonial-body">
              <p className="discription">
                • Honorable Mention, The Mathematical Contest in Modeling, April
                2018.
              </p>
              <p className="discription">
                • Top 10 Team, WeBank Fintech Hackathon for University Students,
                August 2019
              </p>
              <p className="discription">
                • Relevant Classes: Data Structures, Software Engineering, Linux
                & Embedded Communication System.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Education;
