function LeftColumn() {
  return (
    <div className="col-lg-6">
      <div className="d-flex flex-wrap align-content-start h-100">
        <div className="sticky-top-slider position-sticky sticky-top rbt-sticky-top-adjust-two">
          <div className="banner-details-wrapper-sticky slide">
            <div className="thumbnail">
              <img src="felix.jpg" alt="Personal Portfolio" />
            </div>
            <div className="banner-title-area pt--30">
              <h1 className="title" style={{ fontSize: "4rem" }}>
                Hi, I&apos;m{" "}
                <span style={{ fontSize: "4rem" }}>Felix Zhang</span>
                <br />
                <span className="span" style={{ fontSize: "4rem" }}>
                  A Software Engineer!
                </span>
              </h1>
              <p className="disc">
                I&apos;m currently working at{" "}
                <span>
                  <a
                    href="https://www.coinbase.com/about"
                    style={{
                      textDecoration: "underline",
                      fontWeight: "600",
                    }}
                  >
                    Coinbase
                  </a>
                </span>{" "}
                as a front-end & mobile developer, mainly using React and React
                Native.
              </p>
            </div>
            <div className="button-group text-center text-md-start pt--60 pt_md--40 pt_sm--40">
              <a
                className="rn-btn"
                href="https://www.linkedin.com/in/fan-zhang-sv/"
              >
                <i className="fa-brands fa-linkedin-in fa-xl" />
              </a>
              <a className="rn-btn" href="https://github.com/fan-zhang-sv">
                <i className="fa-brands fa-github fa-xl" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LeftColumn;
