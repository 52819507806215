import LeftColumn from "./components/LeftColumn";
import RightCloumn from "./components/RightCloumn";

function App() {
  return (
    <div className="row row--30 pt--100 pt_sm--50">
      <LeftColumn />
      <RightCloumn />
    </div>
  );
}

export default App;
