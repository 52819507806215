function Item({ child, name }: { child: React.ReactNode; name: string }) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        margin: "10px",
        marginRight: "30px",
        marginBottom: "15px",
        width: "60px",
        whiteSpace: "nowrap",
      }}
    >
      <li style={{ margin: "0px", marginBottom: "10px" }}>{child}</li>
      <p style={{ fontSize: "70%" }}>{name}</p>
    </div>
  );
}

function MySkills() {
  return (
    <div
      className="rn-skill-area section-height"
      style={{ paddingBottom: "25px" }}
    >
      <div className="inner slide" style={{ marginBottom: "15px" }}>
        <h5 className="title">My Skills</h5>
        {/* <div className="skill-share-inner pt--100">
          <span className="title">Frameworks & Tools</span>
          <ul className="skill-share d-flex liststyle">
            <li>
              <img src="assets/images/icons/TypeScript.png" alt="TypeScript" />
            </li>
          </ul>
        </div> */}
        <div className="skill-share-inner skill-share-inner-width margin-top-25">
          <span className="title" style={{ fontWeight: "bold" }}>
            Web
          </span>
          <ul
            className="skill-share d-flex liststyle"
            style={{ justifyContent: "flex-start" }}
          >
            <Item
              child={
                <img
                  src="assets/images/icons/TypeScript.png"
                  alt="TypeScript"
                />
              }
              name="TypeScript"
            />
            <Item
              child={<img src="assets/images/icons/js.png" alt="JavaScript" />}
              name="JavaScript"
            />
            <Item
              child={<img src="assets/images/icons/react.png" alt="React" />}
              name="React"
            />
          </ul>
        </div>
        <div className="skill-share-inner skill-share-inner-width margin-top-25">
          <span className="title" style={{ fontWeight: "bold" }}>
            Mobile
          </span>
          <ul
            className="skill-share d-flex liststyle"
            style={{ justifyContent: "flex-start" }}
          >
            <Item
              child={
                <img
                  src="assets/images/icons/TypeScript.png"
                  alt="TypeScript"
                />
              }
              name="TypeScript"
            />
            <Item
              child={<img src="assets/images/icons/js.png" alt="JavaScript" />}
              name="JavaScript"
            />
            <Item
              child={
                <img src="assets/images/icons/react.png" alt="React Native" />
              }
              name="React Native"
            />
            <Item
              child={<img src="assets/images/icons/swift.png" alt="Swift" />}
              name="Swift"
            />
            <Item
              child={
                <img src="assets/images/icons/swiftui.png" alt="SwiftUI" />
              }
              name="SwiftUI"
            />
          </ul>
        </div>
        <div className="skill-share-inner skill-share-inner-width margin-top-25">
          <span className="title" style={{ fontWeight: "bold" }}>
            Others
          </span>
          <ul
            className="skill-share d-flex liststyle"
            style={{ justifyContent: "flex-start" }}
          >
            <Item
              child={<img src="assets/images/icons/python.png" alt="Python" />}
              name="Python"
            />
            <Item
              child={<img src="assets/images/icons/linux.png" alt="Linux" />}
              name="Linux"
            />
          </ul>
        </div>
      </div>
      <a
        href="https://www.flaticon.com/free-icons/javascript"
        title="icons"
        style={{ fontSize: "1rem", color: "gray" }}
      >
        icons created by Freepik - Flaticon
      </a>
    </div>
  );
}

export default MySkills;
